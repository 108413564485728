.subTitle {
  height: 2.5rem;
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1rem;
  font-size: 18px;
}

.divLineUpper {
  height: 1px;
  width: 100%;
  background: #dbdbdb;
}

.divLineLower {
  height: 5px;
  width: 100%;
  background: #f2f2f2;
  margin-bottom: 1rem;
}

.container {
  white-space: pre-line;
  width: 100%;
  padding-bottom: 1rem;
  .subTitle {
    display: flex;
    margin-left: 0rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    font-size: 1rem;
    opacity: 0.6;
    padding-right: 2rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
  }
}

.containerVer2 {
  width: 100%;

  & + & {
    padding-bottom: 0rem;
  }
  .subTitleVer2 {
    display: flex;
    margin-left: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .itemsVer2 {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    font-size: 1rem;
    opacity: 0.6;
    padding-right: 2rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
  }
}

.containerVer3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  .itemsVer3 {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.dropBox-container {
  display: flex;
  padding: 1rem 0 1rem 0rem;
  .dropBox-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    font-size: 1rem;
  }
  .dropBox-select {
    flex: 3;
    padding-right: 2rem;
  }
}

.textFieldBox-container {
  display: flex;
  padding: 1rem 0 1rem 0rem;
  .textFieldBox-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    font-size: 1rem;

    .questionMark {
      opacity: 1;
      font-size: 1rem;
      padding-bottom: 1.4rem;
    }
  }

  .textFieldBox-textField {
    flex: 3;
    padding-right: 2rem;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}

.dropBoxWithBr-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1rem;
  align-items: flex-start;
  .dropBoxWithBr-title {
    opacity: 0.6;
    font-size: 1rem;
  }
  .dropBoxWithBr-select {
    flex: 1;
    padding-top: 1rem;
    // padding-left: 3rem;
    width: 100%;
  }
}

.btnContainerWithRowDirection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.checkbox-container {
  width: 100%;
  padding-bottom: 1rem;
  .subTitle {
    display: flex;
    margin-left: 0rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    font-size: 1rem;
    opacity: 0.6;
    padding-right: 2rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // justify-content: space-evenly;
      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
  }
}

.checkboxVer2-container {
  width: 100%;
  padding-bottom: 1rem;
  .subTitle {
    display: flex;
    margin-left: 0rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    font-size: 1rem;
    opacity: 0.6;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // justify-content: space-evenly;
      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
  }
}

.image-container {
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 1rem;
  .img {
    width: 90%;
    height: 100%;
  }
}

.for-1rem-padding {
  padding-bottom: 1rem;
}

.title-container {
  padding-left: 1rem;
  padding-bottom: 1rem;
  .title-text {
    font-size: 1rem;
    opacity: 0.6;
  }
}

.containerVerFavFod {
  // width: 100%;
  padding-bottom: 1rem;
  padding-left: 1rem;
  .subTitle {
    display: flex;
    margin-left: 0rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    font-size: 1rem;
    opacity: 0.6;
    padding-right: 3rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
    .content-container {
      padding-left: 1rem;
      display: flex;
      align-items: center;
      .content-content {
        padding-bottom: 0.6rem;
        padding-right: 1rem;
      }
      .content-unit {
        padding-bottom: 0.6rem;
        padding-left: 1rem;
      }
    }
  }
}

.containerVerFavFodDrink {
  // width: 100%;
  padding-bottom: 1rem;
  padding-left: 1rem;
  .subTitle {
    display: flex;
    margin-left: 0rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    font-size: 1rem;
    opacity: 0.6;
    padding-right: 3rem;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        padding-bottom: 0.25rem;
        white-space: pre-wrap;
      }
    }
    .content-container {
      padding-left: 1rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      .inner-container {
        width: 100%;
        display: flex;
        align-items: center;
        .content-content {
          padding-bottom: 0.6rem;
          padding-right: 1rem;
          white-space: pre-wrap;
        }
        .content-unit {
          padding-bottom: 0.6rem;
          padding-left: 1rem;
        }
      }
    }
  }
}

.checkBoxWithDropBox-container {
  & + & {
    padding-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    opacity: 0.6;
    justify-content: space-around;
    .title {
      flex: 1;
    }
    .checkBox {
      text-align: center;
      flex: 0.7;
    }

    .digYear {
      text-align: center;
      flex: 1;
    }
    .medication {
      text-align: center;
      flex: 1;
    }
  }
}

.checkBoxFlexDirecRow-container {
  & + & {
    padding-bottom: 0.5rem;
  }
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    opacity: 0.6;
    justify-content: space-around;
    .title {
      flex: 1;
      white-space: pre-line;
    }
    .checkBox {
      text-align: center;
      flex: 0.7;
    }
    .textField {
      flex: 10;
    }
  }
}

.checkBoxFlexDirecRowForOne-container {
  & + & {
    padding-bottom: 0.5rem;
  }
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  padding-left: 1rem;

  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    opacity: 0.6;
    justify-content: space-between;
    .title {
      white-space: pre-line;
    }
    .checkBox {
      text-align: center;
      padding-right: 2rem;
    }
  }
}

.check-blank {
  width: 40%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.checkBoxFlexDirecRowOuter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.accordion-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.accordianItem-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 0.6;
  font-size: 1rem;
  justify-content: space-between;
  white-space: pre-line;
  // .title {
  // }
  // .checkBox {
  // }
}

.autoCompleteSearch-container {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-around;
  padding-bottom: 1rem;

  .autoComplete {
    flex: 5;
  }
  .searchIcon {
    margin-left: 1rem;
    flex: 1;
    background-color: #dbdbdb;
  }
}
